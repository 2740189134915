import { base } from "../utils/base";
import { eventName } from "../utils/eventName";
import { app } from "../app";
var MenuClass = /** @class */ (function () {
    function MenuClass() {
        // class変数
        this._top = base._d.getElementById("js-top");
        this._header = base._d.getElementById("js-header");
        this._headerList = base._d.getElementById("js-headerList");
        this._menuTrigger = base._d.getElementById("js-menuTrigger");
        this._navItemFirst = base._d.querySelectorAll(".js-itemFirst");
        this._navItemSecond = base._d.querySelectorAll(".js-itemSecond");
        this._navChildBackBtn = base._d.querySelectorAll(".js-menuBackButton");
        this._navGrandchildBackBtn = base._d.querySelectorAll(".js-categoryBackButton");
        this._navGrandchildTrigger = base._d.querySelectorAll(".js-thirdTrigger");
        this._navChildTarget = null;
        this._navGrandchildTarget = null;
        this._navChildCat = "";
        this._navGrandchildCat = "";
        this._container = base._d.getElementById("js-container");
        this._main = base._d.getElementById("js-main");
        this.scrollPosY = 0;
        this._mainDirectory = "";
    }
    MenuClass.prototype.init = function () {
        // 以下共通で追加するEVENT
        menu.addEvent();
        var pathname = location.pathname;
        this._mainDirectory = "/" + pathname.split("/")[1] + "/";
    };
    MenuClass.prototype.addEvent = function () {
        // 以下共通で追加するEVENT
        if (menu._menuTrigger) {
            menu._menuTrigger.addEventListener(eventName.CLICK, menu.toggleMenu);
        }
        Array.prototype.forEach.call(menu._navItemFirst, function (el) {
            el.addEventListener(eventName.MOUSE_ENTER, menu.hoverMenu, false);
            el.addEventListener(eventName.MOUSE_LEAVE, menu.blurMenu, false);
            el.addEventListener(eventName.CLICK, menu.openChild, false);
        });
        Array.prototype.forEach.call(menu._navChildBackBtn, function (el) {
            el.addEventListener(eventName.CLICK, menu.closeChild, false);
        });
        Array.prototype.forEach.call(menu._navGrandchildTrigger, function (el) {
            el.addEventListener(eventName.CLICK, menu.openGrandchild, false);
        });
        Array.prototype.forEach.call(menu._navGrandchildBackBtn, function (el) {
            el.addEventListener(eventName.CLICK, menu.closeGrandchild, false);
        });
    };
    MenuClass.prototype.hoverMenu = function () {
        // 以下共通で追加するEVENT
        if (app.LAYOUT !== "PC") {
            return;
        }
        Array.prototype.forEach.call(menu._navItemFirst, function (el) {
            base.removeClass(el, "is-hover");
        });
        base.addClass(this, "is-hover");
    };
    MenuClass.prototype.blurMenu = function () {
        // 以下共通で追加するEVENT
        if (app.LAYOUT !== "PC") {
            return;
        }
        Array.prototype.forEach.call(menu._navItemFirst, function (el) {
            base.removeClass(el, "is-hover");
        });
    };
    MenuClass.prototype.toggleMenu = function () {
        var _a;
        // 以下共通で追加するEVENT
        if (app.LAYOUT === "PC") {
            return;
        }
        if (!base.hasClass(menu._container, "is-open")) {
            menu.scrollPosY = base._w.pageYOffset;
            (_a = menu._main) === null || _a === void 0 ? void 0 : _a.setAttribute("style", "top:" + -menu.scrollPosY + "px");
            base.addClass(menu._container, "is-open");
            // メインディレクトリのグループを展開する。
            var trigger = document.querySelectorAll("[data-url]");
            Array.prototype.forEach.call(trigger, function (t) {
                console.log(t.getAttribute("data-url"), menu._mainDirectory);
                if (t.getAttribute("data-url") === menu._mainDirectory) {
                    base.addClass(t.parentNode, "is-slide");
                    menu._navChildTarget = t.parentNode.querySelector(".js-itemSecond");
                    base.addClass(menu._headerList, "is-secondOpen");
                    menu._navChildCat = t.parentNode.getAttribute("data-category");
                }
            });
            setTimeout(function () {
                base.addClass(menu._container, "is-slide");
            }, 100);
        }
        else {
            base.removeClass(menu._container, "is-slide");
            setTimeout(function () {
                var _a;
                base.removeClass(menu._container, "is-open");
                (_a = menu._main) === null || _a === void 0 ? void 0 : _a.removeAttribute("style");
                base._w.scrollTo(0, menu.scrollPosY);
                // class解除
                if (menu._navChildCat !== "" || menu._navGrandchildCat !== "") {
                    base.removeClass(menu._headerList, "is-secondOpen");
                    Array.prototype.forEach.call(menu._navItemFirst, function (el) {
                        base.removeClass(el, "is-slide");
                    });
                    base.removeClass(menu._headerList, "is-thirdOpen");
                    Array.prototype.forEach.call(menu._navGrandchildTrigger, function (el) {
                        base.removeClass(el, "is-slide");
                    });
                    menu._navChildCat = "";
                    menu._navGrandchildCat = "";
                }
            }, 500);
        }
    };
    MenuClass.prototype.openChild = function (e) {
        if (app.LAYOUT === "PC") {
            return;
        }
        else {
            e.preventDefault();
        }
        var _self = this;
        menu._navChildTarget = _self.querySelector(".js-itemSecond");
        base.addClass(menu._headerList, "is-secondOpen");
        base.addClass(this, "is-slide");
        menu._navChildCat = _self.getAttribute("data-category");
    };
    MenuClass.prototype.closeChild = function (e) {
        if (app.LAYOUT === "PC") {
            return;
        }
        base.removeClass(menu._headerList, "is-secondOpen");
        Array.prototype.forEach.call(menu._navItemFirst, function (el) {
            if (el.getAttribute("data-category") === menu._navChildCat) {
                base.removeClass(el, "is-slide");
            }
        });
        e.stopPropagation();
    };
    MenuClass.prototype.openGrandchild = function (e) {
        if (app.LAYOUT === "PC") {
            return;
        }
        var _self = this;
        menu._navGrandchildTarget = _self.querySelector(".js-thirdTarget");
        base.addClass(menu._headerList, "is-thirdOpen");
        base.addClass(this, "is-slide");
        menu._navGrandchildCat = _self.getAttribute("data-detail");
        // console.log(menu._navChildCat);
        // if(menu._headerList) {
        //
        // }
        e.stopPropagation();
    };
    MenuClass.prototype.closeGrandchild = function (e) {
        if (app.LAYOUT === "PC") {
            return;
        }
        base.removeClass(menu._headerList, "is-thirdOpen");
        Array.prototype.forEach.call(menu._navGrandchildTrigger, function (el) {
            if (el.getAttribute("data-detail") === menu._navGrandchildCat) {
                base.removeClass(el, "is-slide");
                setTimeout(function () {
                    var _a;
                    (_a = menu._navGrandchildTarget) === null || _a === void 0 ? void 0 : _a.removeAttribute("style");
                }, 1000);
            }
        });
        e.stopPropagation();
    };
    MenuClass.prototype.closeMenu = function () {
        var _a;
        if (base.hasClass(menu._container, "is-open")) {
            base.removeClass(menu._container, "is-slide");
            base.removeClass(menu._container, "is-open");
            (_a = menu._main) === null || _a === void 0 ? void 0 : _a.removeAttribute("style");
            if (menu._navChildCat !== "" || menu._navGrandchildCat !== "") {
                base.removeClass(menu._headerList, "is-secondOpen");
                Array.prototype.forEach.call(menu._navItemFirst, function (el) {
                    base.removeClass(el, "is-slide");
                });
                base.removeClass(menu._headerList, "is-thirdOpen");
                Array.prototype.forEach.call(menu._navGrandchildTrigger, function (el) {
                    base.removeClass(el, "is-slide");
                });
                menu._navChildCat = "";
                menu._navGrandchildCat = "";
            }
        }
    };
    return MenuClass;
}());
export var menu = new MenuClass();
