import { app } from "../app";
import { base } from "../utils/base";
var TopClass = /** @class */ (function () {
    function TopClass() {
        // class変数
        this._a = base._d.getElementsByTagName("a");
        this._top = base._d.getElementById("js-top");
        this._loading = base._d.getElementById("js-loading");
        this._loader = base._d.getElementById("js-loader");
        this._header = base._d.getElementById("js-header");
        this._firstMvImg = base._d.querySelectorAll(".js-firstMvImg");
        this._firstMvCover = base._d.getElementById("js-firstMvCover");
        this._firstMvCoverItem = base._d.querySelectorAll(".js-firstMvCoverItem");
        this._creativeFlag = base._d.getElementById("js-creativeText");
        this._firstMvItemCnt = 0;
        this._firstMvCurrent = 0;
        this._firstMvTimer = 2500;
        this._secondMv = base._d.getElementById("js-secondMv");
        this._secondMvHeading = base._d.getElementById("js-secondMvHeading");
        this._secondMvPosY = 0;
        this._secondMvDetail = base._d.getElementById("js-secondMvDetail");
        this._howBottom = base._d.getElementById("js-howBottom");
        this._slideupItem = base._d.getElementById("js-slideupItem");
        this._genreText = base._d.getElementById("js-genreText");
        this._genreCount = base._d.getElementById("js-genreCount");
        this._genreUnit = base._d.getElementById("js-genreUnit");
        this._unitFlag = true;
        this.isScrolling = 0;
        this.timeoutId = null;
    }
    TopClass.prototype.init = function () {
        // 以下共通で追加するEVENT
        // base._w.addEventListener(eventName.RESIZE, top.checkRatio);
        // top.checkRatio();
        top.showLoading();
        base._w.onscroll = function () {
            top.windowScroll();
        };
        app.checkLayout();
        top.checkSecondMv();
    };
    TopClass.prototype.windowScroll = function () {
        clearTimeout(top.timeoutId);
        if (top.isScrolling === 0) {
            requestAnimationFrame(function () {
                top.isScrolling = 1;
                top.checkSecondMv();
            });
        }
        top.timeoutId = setTimeout(function () {
            top.isScrolling = 0;
        }, 500);
    };
    TopClass.prototype.showLoading = function () {
        var _b, _c;
        top._firstMvItemCnt = mainVisualItems.length;
        Array.prototype.forEach.call(top._firstMvImg, function (img) {
            base.removeClass(img, "is-current");
            base.removeClass(img, "is-slide");
        });
        if (top._firstMvImg) {
            base.addClass(top._firstMvImg[top._firstMvCurrent], "is-current");
            (_b = top._genreText) === null || _b === void 0 ? void 0 : _b.innerText = mainVisualItems[top._firstMvCurrent].genre;
            (_c = top._genreCount) === null || _c === void 0 ? void 0 : _c.innerText = mainVisualItems[top._firstMvCurrent].count;
        }
        var dataFlag = localStorage.getItem("msdata");
        if (dataFlag) {
            var nowTime = new Date();
            var nowMs = nowTime.getTime();
            var beforeTimeMs = Number(dataFlag);
            // １日(24H)間隔をあける
            var timeRange = 1 * 1000 * 60 * 60 * 24;
            var timeFlag = Boolean(nowMs - beforeTimeMs > timeRange);
            if (timeFlag) {
                dateSaveLocalStorage();
            }
            else {
                top.createMv();
                top._loading.remove();
            }
        }
        else {
            dateSaveLocalStorage();
        }
        function dateSaveLocalStorage() {
            var latestTime = new Date();
            var latestTimeMs = String(latestTime.getTime());
            localStorage.setItem("msdata", latestTimeMs);
            base.addClass(top._loader, "is-show");
            setTimeout(function () {
                base.addClass(top._loading, "is-loading");
                setTimeout(function () {
                    top.createMv();
                }, 800);
            }, 3000);
        }
    };
    TopClass.prototype.createMv = function () {
        if (top._firstMvImg) {
            base.addClass(top._slideupItem, "is-slideup");
            setTimeout(function () {
                base.addClass(top._firstMvImg[top._firstMvCurrent], "is-slide");
            }, 100);
        }
        top.proceedMv();
    };
    TopClass.prototype.proceedMv = function () {
        Array.prototype.forEach.call(top._firstMvCoverItem, function (item) {
            item.querySelector("div").removeAttribute("style");
            base.removeClass(item, "is-slide");
            setTimeout(function () {
                base.removeClass(top._firstMvCover, "is-slide");
            }, 1000);
        });
        setTimeout(function () {
            Array.prototype.forEach.call(top._firstMvCoverItem, function (item) {
                item.querySelector("div").style.transitionDelay = Math.random() / 2 + "s";
                base.addClass(item, "is-slide");
            });
            setTimeout(function () {
                var _b, _c;
                // 覆われたら画像切り替え
                base.removeClass(top._slideupItem, "is-slideup");
                top._firstMvCurrent = top._firstMvCurrent + 1;
                if (top._firstMvCurrent === top._firstMvItemCnt) {
                    top._firstMvCurrent = 0;
                }
                Array.prototype.forEach.call(top._firstMvImg, function (img) {
                    base.removeClass(img, "is-current");
                    base.removeClass(img, "is-slide");
                });
                if (top._firstMvImg) {
                    base.addClass(top._firstMvImg[top._firstMvCurrent], "is-current");
                    (_b = top._genreText) === null || _b === void 0 ? void 0 : _b.innerText = mainVisualItems[top._firstMvCurrent].genre;
                    (_c = top._genreCount) === null || _c === void 0 ? void 0 : _c.innerText = mainVisualItems[top._firstMvCurrent].count;
                    if (mainVisualItems[top._firstMvCurrent].creativeFlag) {
                        base.removeClass(top._creativeFlag, "is-hide");
                    }
                    else {
                        base.addClass(top._creativeFlag, "is-hide");
                    }
                    if (mainVisualItems[top._firstMvCurrent].unitFlag) {
                        base.removeClass(top._genreUnit, "is-hide");
                    }
                    else {
                        base.addClass(top._genreUnit, "is-hide");
                    }
                    setTimeout(function () {
                        base.addClass(top._firstMvImg[top._firstMvCurrent], "is-slide");
                        base.addClass(top._slideupItem, "is-slideup");
                    }, 100);
                }
                base.addClass(top._firstMvCover, "is-slide");
                setTimeout(function () {
                    // 白が取れたらループ
                    top.proceedMv();
                }, 500);
            }, 750);
        }, top._firstMvTimer);
    };
    TopClass.prototype.checkRatio = function () {
        var ww = base._w.innerWidth;
        var wh = base._w.innerHeight;
        var image = top._secondMv ? top._secondMv.querySelector(".swiper-slide img") : null;
        var iw = image ? image.clientWidth : 0;
        var ih = image ? image.clientHeight : 0;
        if (ww / wh > iw / ih) {
            base.addClass(top._top, "is-beside");
            base.removeClass(top._top, "is-vertical");
        }
        else {
            base.removeClass(top._top, "is-beside");
            base.addClass(top._top, "is-vertical");
        }
    };
    TopClass.prototype.checkSecondMv = function () {
        if (top._secondMv && top._secondMvHeading) {
            top._secondMvPosY = top._secondMv.getBoundingClientRect().top;
            // top._secondMvPosY = top._secondMv.getBoundingClientRect().top + base._w.pageYOffset;
            // console.log("secondMvPosY:" + top._secondMvPosY + "px");
            if (top._secondMvPosY <= 0) {
                top._secondMvHeading.removeAttribute("style");
                base.addClass(top._secondMv, "is-sticky");
                base.addClass(top._howBottom, "is-showButton");
                base.removeClass(top._header, "is-slideUp");
                app.showTopBtn();
                if (app.LAYOUT === "SP") {
                    base.addClass(top._secondMvDetail, "is-show");
                }
                else if (app.LAYOUT === "TB") {
                    base.addClass(top._secondMvDetail, "is-show");
                }
            }
            else {
                if (app.LAYOUT === "SP") {
                    top._secondMvHeading.style.top = "calc(" + (-top._secondMv.getBoundingClientRect().top) + "px + 17.4vh)";
                }
                else if (app.LAYOUT === "TB") {
                    top._secondMvHeading.style.top = "calc(" + (-top._secondMv.getBoundingClientRect().top) + "px + 17.4vh)";
                }
                else {
                    top._secondMvHeading.style.top = "calc(" + (-top._secondMv.getBoundingClientRect().top) + "px + 12vh)";
                }
                base.addClass(top._header, "is-slideUp");
                app.hideTopBtn();
                base.removeClass(top._secondMv, "is-sticky");
            }
            if (top.isScrolling === 1) {
                requestAnimationFrame(function () {
                    top.checkSecondMv();
                });
            }
        }
    };
    return TopClass;
}());
export var top = new TopClass();
