import { top } from "./pages/top";
import { base } from "./utils/base";
import { eventName } from "./utils/eventName";
import { menu } from "./module/menu";
// import Swiper JS
import Swiper from "swiper/bundle";
// import Swiper styles
import "swiper/css/bundle";
var AppClass = /** @class */ (function () {
    function AppClass() {
        // class変数
        this._top = base._d.getElementById("js-top");
        this._a = base._d.getElementsByTagName("a");
        this._header = base._d.getElementById("js-header");
        this._backBtn = base._d.getElementById("js-scrollTop");
        this.BREAK_TB = 1025;
        this.BREAK_SP = 750;
        this.LAYOUT = "PC";
        this._table = base._d.querySelectorAll(".common-table");
        this._groupAccordionButton = base._d.querySelectorAll(".js-group-accordion-button");
    }
    AppClass.prototype.init = function () {
        // 以下共通で追加するEVENT
        if (app._top) {
            top.init();
        }
        else {
            base._w.onscroll = function () {
                app.windowScroll();
            };
        }
        menu.init();
        app.addEvent();
    };
    AppClass.prototype.addEvent = function () {
        var _c;
        // 以下共通で追加するEVENT
        Array.prototype.forEach.call(app._a, function (el) {
            var target = el.getAttribute("href");
            // if (target.match(/^\#/)) {
            //   if (base.ua.indexOf("msie 9") > 0) {
            //     return;
            //   }
            //   el.addEventListener(eventName.CLICK, base.smoothScroll);
            // }
        });
        base._w.addEventListener(eventName.RESIZE, app.checkLayout);
        app.checkLayout();
        base._w.addEventListener(eventName.ORIENTATION_CHANGE, app.checkOrientation);
        app.checkOrientation();
        (_c = app._backBtn) === null || _c === void 0 ? void 0 : _c.addEventListener(eventName.CLICK, function (e) {
            base.scrollWindow("#", { duration: 1000 });
            e.preventDefault();
        });
        if (app.LAYOUT === "SP") {
            Array.prototype.forEach.call(app._table, function (el) {
                el.addEventListener(eventName.SCROLL, app.tableScrollSync, false);
            });
        }
        Array.prototype.forEach.call(app._groupAccordionButton, function (el) {
            el.addEventListener(eventName.CLICK, app.groupAccordion, false);
        });
        app.carousel();
    };
    AppClass.prototype.checkLayout = function () {
        var ww = base._w.innerWidth;
        if (ww > app.BREAK_SP && ww <= app.BREAK_TB) {
            app.LAYOUT = "TB";
        }
        else if (ww <= app.BREAK_SP) {
            app.LAYOUT = "SP";
        }
        else {
            app.LAYOUT = "PC";
            menu.closeMenu();
        }
        console.log("Layout:" + app.LAYOUT);
    };
    AppClass.prototype.checkOrientation = function () {
        switch (base._w.orientation) {
            case -90:
            case 90:
            case 270:
                // alert('端末は横向きでお使いください。');
                base.addClass(base._b, "is-landscape");
                console.log("Orientation:landscape");
                break;
            default:
                base.removeClass(base._b, "is-landscape");
                console.log("Orientation:portrait");
                break;
        }
    };
    AppClass.prototype.windowScroll = function () {
        if (base._w.pageYOffset > 100) {
            app.showTopBtn();
        }
        else {
            app.hideTopBtn();
        }
    };
    AppClass.prototype.showTopBtn = function () {
        base.addClass(app._backBtn, "is-show");
    };
    AppClass.prototype.hideTopBtn = function () {
        base.removeClass(app._backBtn, "is-show");
    };
    AppClass.prototype.removeEvent = function () {
        // 以下共通で削除するEVENT
        Array.prototype.forEach.call(app._a, function (el) {
            var target = el.getAttribute("href");
            // if (target.match(/^\#/)) {
            //   if (base.ua.indexOf("msie 9") > 0) {
            //     return;
            //   }
            //   el.removeEventListener(eventName.CLICK, base.smoothScroll);
            // }
        });
    };
    AppClass.prototype.tableScrollSync = function () {
        var scroll = this.scrollLeft;
        Array.prototype.forEach.call(app._table, function (el) {
            el.scrollLeft = scroll;
        });
    };
    AppClass.prototype.groupAccordion = function () {
        var target = this.closest(".js-group-accordion");
        target.classList.toggle("group__accordion--active");
    };
    AppClass.prototype.carousel = function () {
        var _this = this;
        var elmSwiper = document.querySelectorAll(".js-carousel");
        var carouselNextBtn = document.querySelectorAll(".js-carouselNextBtn");
        var carouselBackBtn = document.querySelectorAll(".js-carouselBackBtn");
        if (elmSwiper.length > 0) {
            for (var i = 0; i < elmSwiper.length; i++) {
                elmSwiper[i].className += i;
                carouselNextBtn[i].className += i;
                carouselBackBtn[i].className += i;
                var swiper = new Swiper(".js-carousel" + i, {
                    loop: true,
                    slidesPerView: 1.2,
                    spaceBetween: 10,
                    speed: 300,
                    paginationClickable: true,
                    wrapperClass: "common-carouselWrap",
                    slideClass: "js-carouselItem",
                    navigation: {
                        nextEl: ".js-carouselNextBtn" + i,
                        prevEl: ".js-carouselBackBtn" + i,
                    },
                    breakpoints: {
                        // 768px以上の場合
                        751: {
                            slidesPerView: 3.2,
                        }
                    }
                });
            }
        }
        var carousel01 = new Swiper("#js-carousel-group01", {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 10,
            speed: 300,
            paginationClickable: true,
            wrapperClass: "common-carouselWrap",
            slideClass: "js-carouselItem",
            navigation: {
                nextEl: ".js-carouselNextBtn",
                prevEl: ".js-carouselBackBtn",
            },
            breakpoints: {
                // 768px以上の場合
                751: {
                    slidesPerView: 2.4,
                }
            }
        });
        var carousel02 = new Swiper("#js-carousel-group02", {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 10,
            speed: 300,
            paginationClickable: true,
            wrapperClass: "common-carouselWrap",
            slideClass: "js-carouselItem",
            navigation: {
                nextEl: ".js-carouselNextBtn",
                prevEl: ".js-carouselBackBtn",
            },
            breakpoints: {
                // 768px以上の場合
                751: {
                    slidesPerView: 2.4,
                }
            }
        });
        var carousel03 = new Swiper("#js-carousel-group03", {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 10,
            speed: 300,
            paginationClickable: true,
            wrapperClass: "common-carouselWrap",
            slideClass: "js-carouselItem",
            navigation: {
                nextEl: ".js-carouselNextBtn",
                prevEl: ".js-carouselBackBtn",
            },
            breakpoints: {
                // 768px以上の場合
                751: {
                    slidesPerView: 2.4,
                }
            }
        });
        var carousel04 = new Swiper("#js-carousel-group04", {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 300,
            paginationClickable: true,
            wrapperClass: "common-carouselWrap",
            slideClass: "js-carouselItem",
            navigation: {
                nextEl: ".js-carouselNextBtn",
                prevEl: ".js-carouselBackBtn",
            },
            breakpoints: {
                // 768px以上の場合
                751: {
                    slidesPerView: 3,
                }
            },
            on: {
                init: function (e) {
                    this._carouselCnt = e.$el[0];
                    this._carouselWrap = this._carouselCnt.children[0];
                    this._carouselWrap.style.transitionDuration = 300 + "ms";
                    app.carouselHeightSet(this._carouselWrap);
                },
            },
        });
        carousel04.on("slideChangeTransitionEnd", function (e) {
            _this._carouselCnt = e.$el[0];
            _this._carouselWrap = _this._carouselCnt.children[0];
            app.carouselHeightSet(_this._carouselWrap);
        });
    };
    AppClass.prototype.carouselHeightSet = function (e) {
        if (app.LAYOUT === "PC" || app.LAYOUT === "TB") {
            this._carouselActive = e.querySelectorAll(".swiper-slide-active > div")[0].offsetHeight;
            this._carouselSecond = e.querySelectorAll(".swiper-slide-next > div")[0].offsetHeight;
            this._carouselThird = e.querySelectorAll(".swiper-slide-next")[0].nextElementSibling.children[0].offsetHeight;
            this._carouselHeight = Math.max(this._carouselActive, this._carouselSecond, this._carouselThird);
        }
        else if (app.LAYOUT === "SP") {
            this._carouselHeight = e.querySelectorAll(".swiper-slide-active > div")[0].offsetHeight;
        }
        e.style.transitionDuration = 300 + "ms";
        e.style.height = this._carouselHeight + "px";
    };
    return AppClass;
}());
export var app = new AppClass();
window.onload = function () {
    app.init();
};
