var EventNameClass = /** @class */ (function () {
    function EventNameClass() {
        // class変数
        this.LOAD = "load";
        this.CLICK = "click";
        this.RESIZE = "resize";
        this.MOUSE_MOVE = "mousemove";
        this.MOUSE_OVER = "mouseover";
        this.MOUSE_LEAVE = "mouseleave";
        this.MOUSE_ENTER = "mouseenter";
        this.MOUSE_OUT = "mouseout";
        this.DOM_MOUSE_SCROLL = "DOMMouseScroll";
        this.ON_MOUSE_WHEEL = "onmousewheel";
        this.SCROLL = "scroll";
        this.TOUCH_START = "touchstart";
        this.TOUCH_MOVE = "touchmove";
        this.TOUCH_END = "touchend";
        this.ORIENTATION_CHANGE = "orientationchange";
    }
    return EventNameClass;
}());
export var eventName = new EventNameClass();
